import '../../../scss/main.scss'
import 'swiper/css';
import Swiper, { Autoplay } from 'swiper';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

Swiper.use([Autoplay]);

window.swiper = new Swiper('.swiper-1', {
  slidesPerView: "auto",
  spaceBetween: 8,
  grabCursor: true,
  loop: true,
  speed: 4000,
  autoplay: {
    delay: 0
  },
  breakpoints: {
    768: {
      spaceBetween: 20,
    },
  },
});

const reveal1Elements = document.getElementsByClassName("reveal1");
for (let i = 0; i < reveal1Elements.length; i += 1) { // create a scene for each element
  gsap.to(reveal1Elements[i], {
        scrollTrigger :{
          trigger: reveal1Elements[i],
          // markers: true,
          start: "bottom bottom",
          end: "bottom top",
          scrub: true,
          toggleActions: "play pause resume pause",
          toggleClass: "visible"
        }
      }
  )
}
